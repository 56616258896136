.landingSection {
  padding: 24px 0;
  max-width: 760px;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 760px) {
  .landingSection {
    padding: 24px 24px;
  }
}

.landingSection.isPadded {
  padding: 24px;
}

@media (min-width: 760px) {
  .landingSection.isPadded {
    padding: 24px 48px;
  }
}
