@value colorRed from '../../styles/colors.module.css';

.cta {
  font-size: 20px;
  position: relative;
}

/* .ctaSubtitle {
  position: absolute;
  bottom: -2em;
  right: 0;
  color: colorRed;
  transform: rotateX('13deg');
} */
