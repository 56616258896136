.landingHero {
  padding: 0;
  max-width: 760px;
  margin: 0 auto;
}

/* @media (min-width: 760px) {
  .landingHero {
    padding: 0 24px;
  }
} */

.landingBody {
  padding: 0 24px;
  margin-top: 35.5vh;
}

@media (min-width: 760px) {
  .landingBody {
    margin-top: 0;
  }
}

.content {
  background-color: #ffffff;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  /* position: relative; */
  border-radius: 0 0 8px 8px;
  /* position: relative;
  top: 0; */
  z-index: 1;
}

/* .content {
  background-color: #ffffff;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  position: relative;
} */

@media (min-width: 760px) {
  .content {
    margin-left: 50%;
    margin-right: 5%;
  }
}

.hero {
  min-height: 100vh;
  position: relative;
}

/* .headline {
  position: absolute;
  top: 50px;
} */

/* @media (min-width: 760px) {
  .headline {
    position: relative;
    top: 0;
  }
} */

.logo {
  padding: 0 24px;
  margin-left: -2px; /* This font is weird and the left side isn't flush :( */
}

@media (min-width: 760px) {
  .logo {
    margin: 0;
  }
}

.ctaMessaging {
  margin: 24px 0;
  display: flex;
  justify-content: center;
}

.headline {
  position: relative;
  height: 25vh;
  display: flex;
  align-items: center;
  padding: 0 24px;
}

@media (min-width: 760px) {
  .headline {
    height: auto;
    display: block;
    padding: 0 24px 24px;
  }
}

.downArrowContainer {
  position: absolute;
  display: none;
  top: 95vh;
  left: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  height: auto;
  flex-direction: column;
}

@media (min-width: 760px) {
  .downArrowContainer {
    display: flex;
  }
}

.downArrowLabel {
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: #999;
  text-transform: uppercase;
}

.downArrow {
  transform: rotate(90deg);
  fill: #999;
}
