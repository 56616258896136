@value colorBlue, colorDarkGrayText, colorRed from '../../styles/colors.module.css';

.callouts {
  display: flex;
  width: 100%;
  max-width: 996px;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;
  gap: 4%;
  row-gap: 24px;
  margin-top: 64px;
}

.callout {
  flex: 1 1 30%;
  min-width: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.calloutIcon {
  position: relative;
  font-size: 3rem;
  font-weight: bold;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 16px;
  margin-bottom: 12px;
  color: colorDarkGrayText;
}

.calloutLabel {
  font-size: 1rem;
  max-width: 100px;
  text-align: center;
  color: colorBlue;
  /* font-weight: bold; */
}

.freeShippingIcon {
  display: flex;
  align-items: center;
}

.iconDollarSign {
  font-family: 'Montserrat';
  display: inline-block;
  font-size: 0.8rem;
}

.iconZero {
  display: inline-block;
}

.iconReasonablePrice {
  display: flex;
  align-items: center;
}

.iconCentsValue {
  font-size: 1.2rem;
}

.slashIcon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.slash {
  width: 4px;
  height: 48px;
  background-color: colorRed;
  transform: rotate(-40deg);
}
