.body {
  padding: 0;
  margin: 1em auto;
  line-height: 1.3;
  font-size: 1.3rem;
}

@media (min-width: 760px) {
  .body {
    padding: 0 0 8px;
    margin: 0 0 1em;
  }
}
