@value colorBlue from '../../../styles/colors.module.css';

.hero {
  color: #444;
  /* color: colorBlue; */
  font-weight: normal;
  position: relative;
  top: 32px;
}

@media (min-width: 760px) {
  .hero {
    max-width: 1024px;
    top: 0;
  }
}

.heading1 {
  font-size: 11vw;
  font-weight: normal;
  font-weight: bold;
  text-align: center;
  line-height: 1.2;
  /* margin-bottom: 0.6em; */
}

@media (min-width: 760px) {
  .heading1 {
    font-size: 48px;
  }
}

/* .list {
  margin-bottom: 12px;
  list-style-type: none;
  font-family: 'Josefin Sans';
} */
