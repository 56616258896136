@value colorRed, colorBlue from '../../styles/colors.module.css';

.sellingPoints {
  margin-top: 12vh;
}

@media (min-width: 760px) {
  .sellingPoints {
    margin-top: 55vh;
  }
}

.title {
  color: colorBlue;
}

.plusses {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 1em;
}

.plus {
  padding: 12px 0;
  /* background-color: #f7f7f7;
  border-radius: 8px; */
  flex: 0 0 100%;
  margin: 12px 2px 12px 0;
  padding-right: 16px;
  font-size: 16px;
  display: flex;
  align-items: center;
}

@media (min-width: 760px) {
  .plus {
    flex: 1 1 48%;
    padding-right: 8px;
    min-width: 248px;
  }
}

@media (min-width: 1235px) {
  .plus {
    flex: 0 1 48%;
  }
}

@media (min-width: 1773px) {
  .plus {
    flex: 0 1 32%;
  }
}

.plusIcon {
  flex: 0 0 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 6px;
}

.plusses a {
  color: colorRed;
}

.benefits {
  font-size: 1.3rem;
  margin-bottom: 3em;
  list-style-type: none;
  padding-left: 4em;
}

.benefits li {
  margin-bottom: 36px;
  position: relative;
}

.benefitIcon {
  position: absolute;
  top: 1em;
  left: -4.25em;
  /* display: flex; */
  /* width: 68px; */
  /* justify-content: center; */

  width: 68px;
  height: 68px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  border-radius: 16px;
}

/* .benefitIconGuarantee {
  composes: benefitIcon;
  margin-left: -8px;
} */

.theCatch {
  margin-top: 48px;
}

.theCatchContent {
  margin-top: 12px;
  font-size: 1.3rem;
  margin-bottom: 64px;
}
