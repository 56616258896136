.base {
  transition: opacity 0.5s ease-out;
}

.hasScrolled {
  composes: base;
  opacity: 0;
}

.hasNotScrolled {
  composes: base;
  opacity: 1;
}
