@value headingColor: #888;
@value howItWorksBorderColor: #ddd;
@value howItWorksNumberBackgroundColor: #bbb;
@value howItWorksBackgroundColor: #fafafa;

.howItWorks {
  position: relative;
  /* display: flex; */
  /* flex-direction: column; */
  background-color: #fff;
  width: auto;
  flex-wrap: wrap;
  margin-top: 10vh;
  margin-bottom: 32px;
  padding: 0 24px;
}

@media (min-width: 760px) {
  .howItWorks {
    padding: 0;
    margin-top: 10vh;
  }
}

.heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
}

@media (min-width: 760px) {
  .heading {
    margin-top: 0;
    /* position: absolute; */
    /* top: -100px; */
    left: 0;
    right: 0;
  }
}

.downArrow {
  position: relative;
  color: headingColor;
  top: -8px;
}

.learnMore {
  font-size: 12px;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.3;
  margin-bottom: 4px;
  font-weight: bold;
  color: headingColor;
  /* color: howItWorksBorderColor; */
}

.sectionTitle {
  font-size: 22px;
  margin-bottom: 24px;
}

.step {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  align-items: center;
  width: 100%;
  flex: 0 0 21%;
  margin-right: 15px;
  padding-bottom: 12px;
}

@media (min-width: 760px) {
  .step {
    flex: 0 0 23%;
    margin-right: 16px;
  }
}

.step:nth-child(5) {
  margin-right: 0;
}

.stepActive {
  composes: step;
}

.stepNumber {
  margin-right: 8px;
  color: #fff;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: howItWorksNumberBackgroundColor;
  border-radius: 999px;
  font-size: 13px;
  font-weight: bold;
}

.stepActive .stepNumber {
  background-color: #ed1c24;
}

.stepGraphic {
  border: 2px solid howItWorksBorderColor;
  border-radius: 4px;
  width: 80px;
  height: 80px;
  flex: 0 0 80px;
  background-color: howItWorksBackgroundColor;
}

.stepActive .stepGraphic {
  border-color: #ed1c24;
}

.stepTitle {
  flex: 1 1 88%;
  margin: 0;
  color: #ed1c24;
}

.stepTitleCentered {
  composes: stepTitle;
  text-align: center;
}

.stepTextContainer {
  flex: 0 0 100%;
}

.stepText {
  position: relative;
  line-height: 1.35;
  margin: 8px 16px 24px 28px;
}
